import React from "react";
import "./machineInfoPage.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import ClawMachineDefault from "../../resorce/claw_machine_default.jpg"
import Swal from "sweetalert2";





const VendorMachineInfo = () => {
  const { machineID } = useParams();
  const [summaryData, setSummaryData] = useState({})
  const [machineData, setMachineData] = useState({});
  const [dates, setDates] = useState([])
  const [tableRows, setTableRows] = useState([])


  const getmachine = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/vendor/${machineID}`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const getMachineSummary = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/vendor/${machineID}/summary`,
        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const clearMachineSummary = async () => {
    const isConfirm = await Swal.fire({
      title: "Are you sure to clear machine history?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
    .delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/vendor/${machineID}/summary`,

      {
        withCredentials: true,
      }
    )
    .then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message,
      });
      getMachineSummary().then((data) => {
      if (data) {
        setSummaryData(data)
        setDates(Array.from(new Set([
          ...Object.keys(data.totalCoinIn),
          ...Object.keys(data.totalPrizeOut)
        ])).reverse())
      }
    });
    })
    .catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error",
      });
    });
  };

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getMonthlySummation(data){
    // Initialize an empty object to store the monthly sums
    const monthlySumsIncome = {};
    const monthlySumsPrizeOut = {};

    // Loop through the totalCoinIn object
    for (const [date, amount] of Object.entries(data.totalCoinIn)) {
        // Extract the year and month from the date string
        const [year, month] = date.split('-');

        // Create a key in the format 'YYYY-MM' to represent the month
        const yearMonth = `${year}-${month}`;

        // Add the amount to the corresponding month
        if (monthlySumsIncome[yearMonth]) {
            monthlySumsIncome[yearMonth] += amount;
        } else {
            monthlySumsIncome[yearMonth] = amount;
        }
    }
    for (const [date, amount] of Object.entries(data.totalPrizeOut)) {
      // Extract the year and month from the date string
      const [year, month] = date.split('-');

      // Create a key in the format 'YYYY-MM' to represent the month
      const yearMonth = `${year}-${month}`;

      // Add the amount to the corresponding month
      if (monthlySumsPrizeOut[yearMonth]) {
        monthlySumsPrizeOut[yearMonth] += amount;
      } else {
        monthlySumsPrizeOut[yearMonth] = amount;
      }
    }
    return {monthlySumsIncome, monthlySumsPrizeOut}
  }


  useEffect(() => {
    getmachine().then((data) => {
      if (data) {
        setMachineData({...machineData,...data});
      }
    });
    getMachineSummary().then((data) => {
      if (data) {
        setSummaryData(data)
        setDates(Array.from(new Set([
          ...Object.keys(data.totalCoinIn),
          ...Object.keys(data.totalPrizeOut)
        ])).reverse())
        const { monthlySumsIncome, monthlySumsPrizeOut } = getMonthlySummation(data);

        // Generate table rows by mapping over the monthlySumsIncome keys
        setTableRows(Object.keys(monthlySumsIncome).map((month) => (
          <tr key={month}>
            <td>{month}</td>
            <td>{monthlySumsIncome[month]}</td>
            <td>{monthlySumsPrizeOut[month] || 0}</td> {/* Default to 0 if no prize out data */}
          </tr>
        )))
      }
    });
  }, []);


  return (
    <div className="playPage-container">
      <div className="description-zone">
        <h2>{machineData?.machineName}</h2>
        <img className="machineImg" src={machineData.image ?? ClawMachineDefault} alt="Machine" />
      </div>
      <div className="container-play">
        <div >
          <button style={{border: "1px solid black"}} onClick={clearMachineSummary}>Clear Counter</button>
          <div className="showQueue">
            <div>
              <h5>
                Total Coin In:{" "}
                <span
                  style={{
                    backgroundColor: "white",
                    border: "2px solid",
                    padding: "3px 25px",
                  }}
                >
                  {summaryData.totalCoinInCurrent ? Object.values(summaryData.totalCoinInCurrent).reduce((acc, value) => acc + value, 0) : "-"}
                </span>
              </h5>
            </div>
            <div style={{ marginLeft: "8px" }}>
              <h5>
                Total Prize Out:{" "}
                <span
                  style={{
                    backgroundColor: "white",
                    border: "2px solid",
                    padding: "3px 25px",
                  }}
                >
                  {summaryData.totalPrizeOutCurrent ? Object.values(summaryData.totalPrizeOutCurrent).reduce((acc, value) => acc + value, 0) : "-"}
                </span>
              </h5>
            </div>
          </div>
          <div className="showQueue">
            <div>
              <h5>
                Today Coin In:{" "}
                <span
                  style={{
                    backgroundColor: "white",
                    border: "2px solid",
                    padding: "3px 25px",
                  }}
                >
                  {summaryData.totalCoinIn ? summaryData.totalCoinIn?.[getCurrentDate()]??0 : "-"}
                </span>
              </h5>
            </div>
            <div style={{ marginLeft: "8px" }}>
              <h5>
                Today Prize Out:{" "}
                <span
                  style={{
                    backgroundColor: "white",
                    border: "2px solid",
                    padding: "3px 25px",
                  }}
                >
                  {summaryData.totalPrizeOut ? summaryData.totalPrizeOut?.[getCurrentDate()]??0 : "-"}
                </span>
              </h5>
            </div>
          </div>
          <div style={{ overflow: "auto", minHeight: "200px",  maxHeight: "400px",}}>
            <table className="table table-bordered mb-0 text-center">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Coin In</th>
                  <th>Prize Out</th>
                </tr>
              </thead>
              <tbody>
                {tableRows.length > 0 ? (
                  tableRows
                ) : (
                  <tr>
                    <td colSpan="3">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div style={{overflow: "auto", height: "400px"}}> 
            <table className="table table-bordered mb-0 text-center">
              <thead>
                <tr>
                  <th>date</th>
                  <th>coinIn</th>
                  <th>prizeOut</th>
                </tr>
              </thead>
              <tbody>
                {dates.map((date) => (
                  <tr key={date}>
                    <td>{date}</td>
                    <td>{summaryData.totalCoinIn[date] || 0}</td>
                    <td>{summaryData.totalPrizeOut[date] || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorMachineInfo;
