import React from "react";
import Joystick from "../components/joystick/Joystick";
import "./playingPage.css";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Countdown from "react-countdown";
import playSfx from "../resorce/mixkit-retro-game-notification-212.wav";
import useSound from "use-sound";
import Modal from "react-modal";
import backGroundSound from "../resorce/X2Download.app - Super Mario Bros. 2 Overworld Theme _ Ragtime _ Boogie Woogie Piano Cover (128 kbps).mp3";
import gamgeOverSfx from "../resorce/mixkit-arcade-retro-game-over-213.wav";
import winSfx from "../resorce/mixkit-payout-award-1934.wav";
import failSfx from "../resorce/mixkit-failure-arcade-alert-notification-240.wav";
import Receiver from "../components/videoReceiver/videoReceiverRTC";
import Listenner from "../components/listenner";
import HowToPlay from "../components/howToPlay/howToPlay";
import { AiFillQuestionCircle } from "react-icons/ai";
import io from "socket.io-client";
import Swal from "sweetalert2";

function getRandomColor() {
  const colors = [
    "#FF0000",
    "#00FFFF",
    "#0000FF",
    "#00008B",
    "#00FF00",
    "#FFFF00",
    "#FF00FF",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}

function changeTextColor() {
  const element = document.querySelector(".color-change");
  if (element) {
    element.style.color = getRandomColor();
  }
}

setInterval(changeTextColor, 700);

const PlayingPage = ({ setIsTopUpModalOpen, setUser }) => {
  const navigate = useNavigate();
  const { prizeId } = useParams();
  const [imgSrc, setImgSrc] = useState("");
  // const [imgSrc2, setImgSrc2] = useState("");
  const socket = useRef();
  const [ws, setWs] = useState(null);
  const [source, setSource] = useState(0);
  const isShowTUT = Boolean(localStorage.getItem("isNotTutorialAgain"));
  const [isShowHowToPlay, setIsShowHowToPlay] = useState(true);
  const [watchNumber, setWatchNumber] = useState(1);
  const [queueNumber, setQueueNumber] = useState(0);
  const [queue, setQueue] = useState([])
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isMyturn, setIsMyTurn] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const prevIsMyTurn = useRef(false);
  const [countdownDate, setCountdownDate] = useState();
  const [isYouWin, setIsYouWin] = useState(false);
  const [playEffect] = useSound(playSfx, { interrupt: true });
  const token = localStorage.getItem("token");
  const userId = JSON.parse(localStorage.getItem("user"))?._id;
  const [isAlreadyShow, setIsAlreadyShow] = useState(false);
  const [backGroundEffect, { stop }] = useSound(backGroundSound, {
    volume: 0.15,
    interrupt: true,
    loop: true,
  });
  const [gamgeOverEffect] = useSound(gamgeOverSfx, {
    interrupt: true,
    volume: 0.25,
  });
  const [winEffect] = useSound(winSfx, {
    volume: 0.25,
    interrupt: true,
  });
  const [failEffect] = useSound(failSfx, {
    volume: 0.25,
    interrupt: true,
  });

  //connect socket for record video
  useEffect(() => {
    if (!socket.current) {
      socket.current = io.connect(process.env.REACT_APP_API_CAM + "/");
    }
  }, []);

  useEffect(() => {
    if (isShowTUT !== undefined) {
      setIsShowHowToPlay(!isShowTUT);
    }
    // WebSocket URL
    // const WS_URL = "wss://keeb-tgo4qagcpa-as.a.run.app";
    const WS_URL = process.env.REACT_APP_WSS_URI;
    // const WS_URL = "ws://localhost:5000";
    // Create the WebSocket connection when the component mounts
      const newWs = new WebSocket(WS_URL);
      newWs.onclose = () => {
        newWs.send(`CLOSE_WEB_CLIENT ${prizeId}`);
      };

      setWs(newWs);

      // Clean up the WebSocket connection when the component unmounts
      return () => {
        if (newWs.readyState === WebSocket.OPEN) {
          newWs.send(`CLOSE_WEB_CLIENT ${prizeId}`);
          newWs.close();
        }
      };
  }, []);

  useEffect(() => {
    // Function to handle incoming messages from the WebSocket
    const handleWsMessage = async (message) => {
      // console.log(message.data);
      const jsonMessage = JSON.parse(message.data); // Parse the incoming JSON message

      if (jsonMessage.numberOfUser !== undefined) {
        setWatchNumber(Number(jsonMessage.numberOfUser));
      }

      if (jsonMessage.queue !== undefined) {
        setQueueNumber(Number(jsonMessage.queue.length));
        setQueue(jsonMessage.queue)
        const isInqueue = jsonMessage.queue.includes(userId)
        setIsShowVideo(isInqueue)
      }

      if (jsonMessage.isWin !== undefined) {
        setIsWin(Boolean(jsonMessage.isWin));
      }

      if (
        jsonMessage.isWin !== undefined &&
        jsonMessage.playBy !== undefined &&
        !isYouWin
      ) {
        if (
          jsonMessage.isWin &&
          jsonMessage.playBy ===
            JSON.parse(localStorage.getItem("user"))?._id &&
          jsonMessage.playBy != null
        ) {
          setIsYouWin(true);
        }
      }

      if (jsonMessage.playBy !== undefined) {
        // console.log(jsonMessage.playBy);
        setIsMyTurn(
          jsonMessage.playBy == JSON.parse(localStorage.getItem("user"))?._id &&
            jsonMessage.playBy != null
        );
      }

      if (jsonMessage.playBy !== undefined && jsonMessage.queue !== undefined) {
        setShowButton(
          !jsonMessage.queue.includes(
            JSON.parse(localStorage.getItem("user"))?._id
          ) &&
            !(
              jsonMessage.playBy ==
                JSON.parse(localStorage.getItem("user"))?._id &&
              jsonMessage.playBy != null
            )
        );
      }

      if (jsonMessage.playEndTime !== undefined) {
        // console.log(jsonMessage.playEndTime);
        setCountdownDate(jsonMessage.playEndTime);
      }

      if (jsonMessage.isOnline !== undefined) {
        // console.log(jsonMessage.isOnline);
        setIsOnline(Boolean(jsonMessage.isOnline));
      }

      if (jsonMessage.video) {
        const uint8Array = new Uint8Array(jsonMessage.video.data);
        const binary = uint8Array.reduce(
          (bin, byte) => bin + String.fromCharCode(byte),
          ""
        );
        const base64Image = `data:image/jpeg;base64,${btoa(binary)}`;
        const currentCam = uint8Array.slice(12, 13)[0];

        // Use the appropriate setter function to update the image source based on the current camera
        // console.log(source + 1, currentCam);
        if (currentCam === source + 1) {
          setImgSrc(base64Image);
        }
        // } else {
        //   setImgSrc2(base64Image);
        // }
      }
    };
    // Attach the WebSocket message handler when the WebSocket is available
    if (ws) {
      ws.onopen = () => {
        console.log(`Connected to ${ws.url}`);
        ws.send(
          `INIT_WEB_CLIENT ${prizeId} ${userId}`
        );
      };

      ws.onmessage = handleWsMessage;

      ws.onclose = () => {
        ws.send(`CLOSE_WEB_CLIENT ${prizeId}`);
      };
    }
  }, [ws, prizeId ]);
  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (ws) {
        ws.send(`CLOSE_WEB_CLIENT ${prizeId}`); // Adjust the message according to your requirement
        ws.close(); // Close the WebSocket before the page unloads
      }
    };

    window?.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window?.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [ws, prizeId]);

  const getPrize = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/${prizeId}/user`,

        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const getCurrentPaid = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/currentPaid/${prizeId}`,
        {
          withCredentials: true,
        }
      );
      return response.data.data.currentPaid;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const play = async () => {
    if (!token) {
      navigate("/login");
    }
    if (JSON.parse(localStorage.getItem("user"))?.coin < prizeData?.price) {
      failEffect();
      setIsTopUpModalOpen(true);
      return;
    }
    try {
      if (isOnline) {
        playEffect();

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`;
        await axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/payment/play`,
            { prizeId: prizeId },
            {
              withCredentials: true,
            })
          .then((response ) => {
            console.log(response)
            const user = response.data.user;
          // console.log(user);
          localStorage.setItem("user", JSON.stringify(user));
          setUser(user);
          getCurrentPaid().then((data) => {
            if (data) {
              setCurrentPaid(data);
            }
          });
          setIsAlreadyShow(false);
          // window.location.reload();
          })
          .catch(({ response }) => {
            Swal.fire({
              text: response.data.message,
              icon: "error",
            });
          });
      }
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  useEffect(() => {
    prevIsMyTurn.current = isMyturn;
  }, [isMyturn]);

  useEffect(() => {
    // Play the background sound when isMyTurn changes from false to true
    if (prevIsMyTurn.current === true && isMyturn === true) {
      backGroundEffect();
    }

    // Play the game over sound when isMyTurn changes from true to false
    if (prevIsMyTurn.current === false && isMyturn === false) {
      stop();
      gamgeOverEffect();
    }
    return ()=>{
      stop();

    }
  }, [isMyturn]);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed && !isMyturn) {
      // Render a completed state
      gamgeOverEffect();
      return <></>;
    } else {
      // Render a countdown
      return <h3 className="warnText" style={{ margin: "2px", color: "red" }}>Time: {seconds} s</h3>;
    }
  };

  const [prizeData, setPrizeData] = useState({
    _id: "",
    isOnline: false,
    image: "",
    prizeName: "Not Found",
    price: 99999,
    guaranteePrice: 99999,
    description: "",
    machineSelectedId: null,
  });

  const [currentPaid, setCurrentPaid] = useState(0);

  useEffect(() => {
    getPrize().then((data) => {
      if (data) {
        setPrizeData(data);
      }
    });
    if (token) {
      getCurrentPaid().then((data) => {
        if (data) {
          setCurrentPaid(data);
        }
      });
    }
  }, []);

  const dynamicWidth = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--dynamic-width");

  return (
    <div className="play-page" >
      <div className="playPage-container">
        {isShowTUT !== undefined ? (
          <HowToPlay
            isShowHowToPlay={isShowHowToPlay}
            setIsShowHowToPlay={setIsShowHowToPlay}
          />
        ) : null}
        <Modal
          className="modal-nav"
          isOpen={isYouWin && !isAlreadyShow}
          onAfterOpen={() => {
            setIsYouWin(true);
            winEffect();
          }}
          onRequestClose={() => {
            setIsYouWin(false);
            setIsAlreadyShow(true);
          }}
          contentLabel="Example Modal"
        >
          <h2 className="color-change">Congratulations you win !!</h2>

          <div className="btn-panel">
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                setIsYouWin(false);
                setIsAlreadyShow(true);
              }}
            >
              OK
            </button>
          </div>
        </Modal>
        <div className="description-zone">
          <h2>{prizeData?.prizeName}</h2>
          <p>{prizeData?.description}</p>
          <p> ราคา: {prizeData?.price} coin/play</p>
          <img className="machineImg" src={prizeData?.image} alt="Machine" />
          <p>
            สถานะตู้:{" "}
            {isOnline ? (
              <span style={{ color: "green" }}>Online</span>
            ) : (
              <span style={{ color: "red" }}>Offline</span>
            )}
          </p>
        </div>
        <div className="container-play">
          <div className="container-video-play">
            <div className="no-mobile">
              <h5 className="guarantee">Guarantee Prize</h5>
              <div className="progessBarContainer">
                <div>
                  <div
                    className="progessBar"
                    style={{
                      width: `${parseFloat(dynamicWidth)}px`,
                    }}
                  >
                    <div
                      className="inside-progessBar"
                      style={{
                        width: `${
                          (currentPaid / prizeData.guaranteePrice) *
                          parseFloat(dynamicWidth)
                        }px`,
                      }}
                    ></div>
                    <p style={{ color: "black" }}>
                      {((currentPaid / prizeData.guaranteePrice) * 100).toFixed(
                        2
                      )}
                      %
                    </p>
                  </div>
                </div>
                <div>
                  <p style={{ margin: "0px", color: "#24292e" }}>
                    ราคาสินค้า
                  </p>
                  <p style={{ margin: "3px", color: "#24292e" }}>
                    {prizeData.guaranteePrice} coin
                  </p>
                </div>
              </div>
              <div className="showQueue">
                <div>
                  <h5>
                    ผู้ชม:{" "}
                    <span
                      style={{
                        backgroundColor: "white",
                        border: "2px solid",
                        padding: "3px 25px",
                      }}
                    >
                      {watchNumber}
                    </span>
                  </h5>
                </div>
                <div style={{ marginLeft: "8px" }}>
                  <h5>
                    คิว:{" "}
                    <span
                      style={{
                        backgroundColor: "white",
                        border: "2px solid",
                        padding: "3px 25px",
                      }}
                    >
                      {queueNumber}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="video-container">
              <p className="warnText" style={{color:"red"}}>* รีโหลดหน้าหากภาพค้างหรือจอดำเกมจะยังดำเนินต่อไปให้</p>
              {(isShowVideo || isMyturn || (0 < watchNumber && watchNumber <= 10))? 
                prizeData.machineSelectedId ? 
                  <Listenner
                    machineID={prizeData.machineSelectedId}
                    camNumber={source}
                  ></Listenner> 
                  
                : null
              : <p style={{color:"black"}}>เนื่องจากมีผู้ชมจำนวนมาก วีดีโอจะแสดงแค่คนที่อยู่ในคิวรอเล่นเท่านั้น</p>}
              {/* {prizeData.machineSelectedId ? 
                <Listenner
                  machineID={prizeData.machineSelectedId}
                  camNumber={source}
                ></Listenner> 
              : null} */}
            </div>
            <button
              type="button"
              style={{ marginBottom: "10px" }}
              onClick={() => {
                setSource((source + 1) % 2);
              }}
            >
              Switch Camera
            </button>
            <div className="no-mobile">
              {isMyturn && countdownDate ? ( // Only render Countdown if timeRemaining is not 0 (or NaN)
                <Countdown date={countdownDate} renderer={renderer} overtime={true}/>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="container-controller">
            <div className="on-pc">
              <h5 className="guarantee">Guarantee Prize</h5>
              <div className="progessBarContainer">
                <div>
                  <div
                    className="progessBar"
                    style={{
                      width: `${parseFloat(dynamicWidth)}px`,
                    }}
                  >
                    <div
                      className="inside-progessBar"
                      style={{
                        width: `${
                          (currentPaid / prizeData.guaranteePrice) *
                          parseFloat(dynamicWidth)
                        }px`,
                      }}
                    ></div>
                    <p style={{ color: "black" }}>
                      {((currentPaid / prizeData.guaranteePrice) * 100).toFixed(
                        2
                      )}
                      %
                    </p>
                  </div>
                </div>
                <div>
                  <p style={{ margin: "0px", color: "#24292e" }}>
                    ราคาสินค้า
                  </p>
                  <p style={{ margin: "3px", color: "#24292e" }}>
                    {prizeData.guaranteePrice} coin
                  </p>
                </div>
              </div>
              {isMyturn && countdownDate ? ( // Only render Countdown if timeRemaining is not 0 (or NaN)
                <Countdown date={countdownDate} renderer={renderer} overtime={true}/>
              ) : (
                <></>
              )}
              <div className="showQueue">
                <div>
                  <h5>
                    ผู้ชม:{" "}
                    <span
                      style={{
                        backgroundColor: "white",
                        border: "2px solid",
                        padding: "3px 25px",
                      }}
                    >
                      {watchNumber}
                    </span>
                  </h5>
                </div>
                <div style={{ marginLeft: "8px" }}>
                  <h5>
                    คิว:{" "}
                    <span
                      style={{
                        backgroundColor: "white",
                        border: "2px solid",
                        padding: "3px 25px",
                      }}
                    >
                      {queueNumber}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="play">
              {showButton ? (
                !isOnline ? (
                  <h4 style={{ color: "red" }}>Machine not available</h4>
                ) : (
                  <button onClick={play}>Play</button>
                )
              ) : isMyturn ? (
                <>
                <Joystick
                  ws={ws}
                  machineID={prizeData.machineSelectedId}
                  setImgNumber={setSource}
                />
                <p style={{color:"black"}}>สามารถกดปุ่ม W, A, S, D, Space เพื่อควบคุมได้</p>
                </>
              ) : (
                <h4>กรุณารอถึงคิวในอีก {queue.findIndex(e=>e===userId) +1} คิว</h4>
              )}
            </div>
          </div>
          <div style={{ alignSelf: "start" }}>
            <AiFillQuestionCircle
              onClick={() => {
                setIsShowHowToPlay(true);
                // console.log(isShowHowToPlay);
              }}
            >
              how to play
            </AiFillQuestionCircle>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayingPage;
