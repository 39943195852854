import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function CreatePrize() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [stockAmount, setStockAmount] = useState(0);
  const [price, setPrice] = useState();
  const [guaranteePrice, setGuaranteePrice] = useState();
  const [machineSelectedId, setMachineSelectedId] = useState(null);
  const [machines, setMachines] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchMachine();
  }, []);

  const fetchMachine = async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/`)
      .then(({ data }) => {
        setMachines(data.data);
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const createPrize = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("stockAmount", stockAmount);
    formData.append("guaranteePrice", guaranteePrice);
    formData.append("machineSelectedId", machineSelectedId);

    // Append the image file only if it has been selected
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    // console.log(formData);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file upload
        },
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate("/admin/prize");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(()=>{
        setIsSubmitting(false)
      });
  };

  return (

      <div className="col-12 col-sm-12 col-md-6">
        <div className="my-card">
          <div className="card-body">
            <h4 className="create-title">Create Prize</h4>
            <hr />
            <div className="form-wrapper">
              {Object.keys(validationError).length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul className="mb-0">
                        {Object.entries(validationError).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <Form onSubmit={createPrize}>
                <Row>
                  <Col>
                    <Form.Group controlId="Name">
                      <Form.Label>Name</Form.Label>
                      <br/>
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-0">
                  <Col>
                    <Form.Group controlId="Description">
                      <Form.Label>Description</Form.Label>
                      <br/>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={description}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="Image" className="mb-0">
                      <Form.Label>Image</Form.Label>
                      <br/>
                      {selectedImage ? 
                      <img
                        alt="not found"
                        width={"200px"}
                        src={URL.createObjectURL(selectedImage)}
                      />
                      : null}
                      
                      <br/>
                      <Form.Control
                        type="file"
                        name="prizeImage"
                        // Event handler to capture file selection and update the state
                        onChange={(event) => {
                          console.log(event.target.files[0]); // Log the selected file
                          setSelectedImage(event.target.files[0]); // Update the state with the selected file
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="Price" className="mb-0">
                      <Form.Label>Price: </Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        value={price}
                        onChange={(event) => {
                          setPrice(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="GuaranteePrice" className="mb-2">
                      <Form.Label>GuaranteePrice: </Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        value={guaranteePrice}
                        onChange={(event) => {
                          setGuaranteePrice(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="StockAmount" className="mb-0">
                      <Form.Label>StockAmount: </Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        value={stockAmount}
                        onChange={(event) => {
                          setStockAmount(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="machineSelectedId" className="mb-2">
                        <Form.Label>Machine Selected</Form.Label>
                        <br/>
                        <Form.Select className="input_update"
                          value={machineSelectedId}
                          onChange={(event) => {
                            const selectedValue = event.target.value === "" ? null : event.target.value;
                            setMachineSelectedId( selectedValue );
                          }}
                        >
                          <option value={""}>ยังไม่ได้เครื่อง</option>
                          {machines.map(machine=>{
                            return <option value={machine._id}>{machine.machineName}</option>
                          })}
                          
                          
                        </Form.Select>
                      </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  className="mt-2"
                  size="lg"
                  block="block"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
  );
}
