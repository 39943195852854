import React, { useState } from "react";
import "./couponPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CouponPage = () => {
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmitCoupon = async (e) => {
    e.preventDefault();
    if (isSubmitting){
      return
    }
    setIsSubmitting(true)
    const payload = {
      code:couponCode
    };
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coupon/submit`, payload)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate(`/`);
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
        });
      })
      .finally(()=>{
        setIsSubmitting(false)
      })
  };

  return (
    <>
      <div className="my-container" style={{height:"70vh"}}>
        <div className="my-card">
          <h2>โค้ดคูปอง🎉</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label style={{textAlign:"left", margin:"0"}}>รหัสคูปอง</label>
            <input
              type="text"
              name="couponCode"
              placeholder="กรอกคูปอง"
              required
              onChange={(e)=>setCouponCode(e.target.value)}
            />
            <button
              style={{ userSelect: "none" }}
              type="submit"
              disabled={couponCode.length === 0}
              onClick={handleSubmitCoupon}
            >
              ยืนยัน
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CouponPage;
