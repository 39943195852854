import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import RTCMultiConnection from "rtcmulticonnection-react-js";
import io from "socket.io-client";
import styled from "styled-components";
import MRecordRTC from "recordrtc";
import axios from "axios";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 85px;
  margin-bottom: 20px;
  background-color: white;
  padding: 10px;
`;

const Status = styled.h5`
  margin: 5px;
`;


const VideoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;


const VideoBroadcasting = () => {
  const connection = useRef();
  const { machineID, camNumber, sourceNumber } = useParams();
  const [stream, setStream] = useState();
  const [deviceName, setDeviceName] = useState("NaN");
  const socket = useRef();
  const userVideo = useRef();
  const [isOnline, setIsOnline] = useState(false);
  const recorder = useRef();
  // const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    try {
      if (!connection.current) {
        connection.current = new RTCMultiConnection();

        connection.current.socketURL = process.env.REACT_APP_API_CAM + "/";

        socket.current = io.connect(process.env.REACT_APP_API_CAM + "/");
        socket.current.emit("machine connecting", {
          machineID: machineID,
          camnumber: camNumber,
        });

        connection.current.socketMessageEvent = "video-broadcast-demo";

        connection.current.onMediaError = ()=>{
          window.location.reload();
        }

        connection.current.session = {
          audio: false,
          video: true,
          oneway: true,
        };

        // first step, ignore default STUN+TURN servers
        connection.current.iceServers = [];

        // second step, set STUN url
        connection.current.iceServers.push({
          urls: process.env.REACT_APP_STUN_SERVER,
        });

        // last step, set TURN url (recommended)
        connection.current.iceServers.push({
          urls: process.env.REACT_APP_TURN_SERVER,
          username: process.env.REACT_APP_TURN_USER,
          credential: process.env.REACT_APP_TURN_PASSWORD,
        });

        connection.current.dontCaptureUserMedia = true;

        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            const videoDevices = devices.filter(
              (device) => device.kind === "videoinput"
            );
            // console.log(videoDevices);
            if (videoDevices.length === 0) {
              console.error("No video input devices found");
              return;
            }

            // Assuming you want to use the first available webcam device.
            const selectedDevice = videoDevices[Number(sourceNumber)].deviceId;
            // console.log(videoDevices[Number(sourceNumber)].label);
            setDeviceName(videoDevices[Number(sourceNumber)].label);

            const constraints = {
              video: {
                deviceId: { exact: selectedDevice },
                width: { ideal: 640 },
                height: { ideal: 480 },
              },
              audio: false,
            };

            return navigator.mediaDevices.getUserMedia(constraints);
          })
          .then((stream) => {
            setStream(stream);
            socket.current.on("start recording", () => {
              console.log("start rec");
              recorder?.current?.stopRecording();
              startRecording(stream);
            });

            socket.current.on("stop recording", (data) => {
              console.log("stop rec");
              const {isOnlineSave, isLocalSave, prizeId, userId} = data
              stopRecording(isOnlineSave, isLocalSave, prizeId, userId);
            });
            if (userVideo.current) {
              userVideo.current.srcObject = stream;
            }
            connection.current.videosContainer =
              document.getElementById("videos-container");
            connection.current.attachStreams = [stream];
            showCam()
            setInterval(() => {
              const socketConnected = socket.current && socket.current.connected;
              connection.current.checkPresence(`${machineID}${camNumber}`, function(isRoomExist, roomid, error) {
                if (!isRoomExist) {
                  window.location.reload();
                } 
            });
              if (!socketConnected ) {
                window.location.reload();
              }
            }, 60 * 1000);
          });
        // const peerCleanup = () => {
        //   i++;
        //   if (!(i % 3)) {
        //     // Attempt to invoke garbage collection
        //     queueMicrotask(() => {
        //       let img = document.createElement("img");
        //       img.src = window.URL.createObjectURL(new Blob([new ArrayBuffer(5e+7)])); // 50MB buffer
        //       img.onerror = function () {
        //         window.URL.revokeObjectURL(this.src);
        //         img = null;
        //       };
        //     });
        //   }
        // };
        const showCam = () => {
          connection.current.sdpConstraints.mandatory = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false,
          };
          connection.current.open(`${machineID}${camNumber}`, function (isRoomOpened) {
            setIsOnline(isRoomOpened);
            console.log(connection.current.sessionid);
            if (!isRoomOpened) {
              window.location.reload();
            }
            // peerCleanup();
          });
        };
      }
    }catch (error) {
      handleError(error); // Handle the error if it occurs
    }
  }, []);
  

  const startRecording = (stream) => {
    if (stream) {
      recorder.current = new MRecordRTC(stream, {
        type: "video",
        mimeType: 'video/mp4' 
      });
      // recorder.current.ondataavailable = (event) => {
      //   console.log("data-available");
      //   if (event?.data.size > 0) {
      //     recordedChunks.current.push(event.data);
      //   }
      // }

      recorder.current.startRecording();
      // setIsRecording(true);
    } else {
      console.error("Cannot start recording, stream is undefined");
    }
  };
  const stopRecording = (isOnlineSave, isLocalSave, prizeId, userId) => {
    if (recorder.current) {
      // recorder.current.stopRecording(function (url) {
      //   const videoBlob = recorder.current.getBlob();
      //   const videoUrl = URL.createObjectURL(videoBlob);

      //   const videoElement = document.getElementById("saveVDO");

      //   if (videoElement) {
      //     videoElement.src = videoUrl;
      //     videoElement.play();
      //   } else {
      //     console.error("Video element not found in the DOM");
      //   }
      //   console.log(videoUrl);
      //   recorder.current.save(); // Save the recorded video
      //   setIsRecording(false);
      console.log('Hanging up the call ...');
      const onStop = async (event) => {
        let blob = recorder.current.getBlob();
  
        let reader = new FileReader();
        reader.readAsDataURL(blob);
  
        reader.onloadend = async () => {
          let base64Data = reader.result?.toString();
          if (isOnlineSave){
            await uploadVideo(base64Data, prizeId, userId);
          }
  
        };
        if(isLocalSave){
          recorder.current.save(); // Save the recorded video
        }
        // setIsRecording(false);
      };
  
      recorder.current.stopRecording(onStop);
      
    } else {
      console.error("Cannot stop recording, recorder is undefined");
    }
  };

	const uploadVideo = async (base64, prizeId, userId) => {
		console.log('uploading to backend...');
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/play-record/upload-video`, JSON.stringify({
        data: base64,
        prizeId,
        userId,
        fileName: `${machineID}-${camNumber}-${new Date().getTime()}`,
      }),
      {
        headers: {
          "Content-Type": "application/json", // Important for file upload
        },
      })
      .then((response) => {
				console.log('successfull session', response.status);
			})
      .catch(({ response }) => {
        console.log(response)
        }
      );
	};

  const handleError = (error) => {
    console.error("debug: mobil: ",error)
    if (error.message.includes("Cannot create so many PeerConnections")) {
      console.error("Too many PeerConnections. Reloading page...");
      window.location.reload();
    } else {
      console.error("Error occurred:", error);
    }
  };

  return (
    <Container>
      {/* <button
        onClick={isRecording ? stopRecording : () => startRecording(stream)}
      >
        {isRecording ? "Stop Record" : "Start Record"}
      </button> */}
      <h2>Strat to stream</h2>
      {isOnline ? (
        <Status style={{ color: "green" }}>Status: Online</Status>
      ) : (
        <Status style={{ color: "red" }}>Status: Offline</Status>
      )}

      {/* <Video id="saveVDO"></Video> */}
      <VideoContainer id="videos-container">
        {/* {stream ? <Video playsInline muted ref={userVideo} autoPlay /> : null} */}
      </VideoContainer>

      <Status>
        machineID: <p>{machineID}</p>
      </Status>
      <Status>
        cam number: <p>{camNumber}</p>
      </Status>
      <Status>
        device number: <p>{deviceName}</p>
      </Status>

      <hr style={{ width: "80%" }} />
      {/* <div style={{ display: "flex", flexDirection: "column" }}>
        <Status>Users:</Status>
        {onlineUsers.map((user) => {
          return <CallButton>{`${user.user.name}`}</CallButton>;
        })}
      </div> */}
    </Container>
  );
};

export default VideoBroadcasting;
