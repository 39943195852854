import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import "./crud.css";
import Select from "react-select";
export default function List() {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("vendor");
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/`,

        {
          withCredentials: true,
        }
      );
      setUsers(response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const deleteUser = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/${id}`,

        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchUsers();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  return (

      <div className="crud-container">
        <div className="row">
          <div className="col-12" style={{fontSize:"16px"}} >
            <Select 
              onChange={event => setFilter(event?.value)}
              options={[
                { value: "vendor", label: "vendor" },
                { value: "user", label: "user" },
                { value: "admin", label: "admin" }
              ]}
              defaultValue={{ value: "vendor", label: "vendor" }}
            />
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>email</th>
                    <th>tel</th>
                    <th>role</th>
                    {filter != "user"? 
                      <>
                        <th>Store Name</th>
                        <th>Line Id</th>
                      </>:
                      null
                    }
                    <th>coin</th>
                    <th>items</th>
                    <th>createdAt</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.filter(user=>user.role == filter).length > 0 &&
                    users.filter(user=>user.role == filter).map((row, key) => (
                      <tr key={row._id} style={{color: row.isEnablePayment? "green": null}}>
                        <td>{row.name}</td>
                        <td>{row.email}</td>
                        <td>{row.tel}</td>
                        <td>{row.role}</td>
                        {filter != "user"? 
                        <>
                        <td>{row.storeName}</td>
                        <td>{row.lineId}</td>
                        </>:
                        null
                        }
                        <td>{row.coin}</td>
                        <td>{row.prize.length !== 0 ? row.prize.map(i=><li>{i}</li>) : "-"}</td>
                        <td>{row?.createdAt?.split('T')?.[0] || '-'}</td>
                        <td>
                          <Link
                            to={`user/edit/${row._id}`}
                            className="btn btn-success"
                          >
                            Edit
                          </Link>
                          <Button
                            className="btn"
                            variant="danger"
                            onClick={() => deleteUser(row._id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}
