import React, { useState, useEffect, useRef } from "react";
import "./machinesPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Slideshow from "../components/slideImage/slideImage";
import styled from 'styled-components';
import coinIcon from '../resorce/coin-icon.png'

const FloatingSidebar = styled.div`
  position: fixed;
  right: 8px; /* Or left: 0; for the left side */
  width: 60vw;
  max-width: 400px;
  background-color: #24292e;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  text-align: center;

  @media (max-width: 375px) {
    p {
      font-size: smaller
    }
  }
`;


const MachinesPage = () => {
  const navigate = useNavigate();
  const [machines, setMachines] = useState([]);
  const [typesWithLength, setTypesWithLength] = useState();
  const token = localStorage.getItem("token");
  const [showItemLimit,setShowItemLimit] = useState();
  const [lastWinVideo, setLastWinVideo] = useState()
  const [isShowWinner, setIsShowWinner] = useState(true);
  const containerRefs = useRef([]);
  useEffect(() => {
    getmachines().then((res) => {
      if (res) {
        const classify_machine = {};
        res?.data.forEach((machine) => {
          if (machine.isEnable){
            const type = machine.name.trim().split(" ")[0];
            if (classify_machine[type]) {
              classify_machine[type].push(machine);
            } else {
              classify_machine[type] = [machine];
            }
          }
        });
        for (const type in classify_machine) {
          if (
            classify_machine[type].filter((m) => m.price < 1000).length === 0
          ) {
            delete classify_machine[type];
            continue;
          }
          classify_machine[type] = classify_machine[type].sort((a, b) => {
            if (a.price !== b.price) {
              return a.price - b.price;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        }
        let l = Object.keys(classify_machine).map((type) => ({
          isExpand: false,
          type,
          length: classify_machine[type].length,
        }));

        // // Sort the types by the length of machines in descending order
        // l.sort((a, b) => b.length - a.length);
        // console.log(l);

        // Separate the special type
        const specialType = l.find(
          (typeObj) => typeObj.type === "ทดลองระบบคีบฟรี"
        );
        if (specialType) {
          l = l.filter((typeObj) => typeObj.type !== specialType.type);
        }

        // Sort the types (excluding the special type) by the length of machines in descending order
        l.sort((a, b) => b.length - a.length);

        // Prepend the special type to the sorted array
        if (specialType) {
          l.unshift(specialType);
        }
        setTypesWithLength(l);
        setMachines(classify_machine);
      }
    });
    getLastWinVideo().then((res)=>{
      console.log(res)
      setLastWinVideo(res)
    })
  }, []);

  useEffect(() => {
    // Loop through all container refs and compute column counts
    typesWithLength?.forEach((_, index) => {
      const currentContainer = containerRefs.current[index];
      if (currentContainer) {
        const computedStyle = window.getComputedStyle(currentContainer);
        const columns = computedStyle.getPropertyValue('grid-template-columns');
        const columnCount = columns.split(' ').length;

        // Update the state with the column count for this container
        setShowItemLimit(columnCount*4-1);
      }
    });
  }, [typesWithLength]); // Run when typesWithLength changes

  const getmachines = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/user`,

        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const getLastWinVideo = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/play-record/last`,

        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const handleClickMachine = (prizeId) => {
    if (!token) {
      navigate("/login");
    } else {
      navigate(`play/${prizeId}`);
    }
  };
  

  return (
    <div className="container-machine-page">
      {lastWinVideo && isShowWinner ?
        <FloatingSidebar>
        <Header>
            <p style={{ color: "white", margin: "0px" }}>
              ผู้ชนะล่าสุด: <p style={{ color: "#f1c40f", margin: "0px" }}>{lastWinVideo.winnerName}</p>
            </p>
            <p style={{ color: "white", margin: "0px" }}>
              รางวัล: <p style={{ color: "#f1c40f", margin: "0px" }}>{lastWinVideo.prizeName}</p>
            </p>
          <button onClick={()=>{setIsShowWinner(false)}}>ปิด</button>
        </Header>
        <div
          style={{
            height: "75vw",
            maxHeight: "500px",
          }}
        >
          <video
            className="video-rotate"
            style={{
              justifyContent:"center",
              height:"55vw",
              objectFit: "cover",
              position: "fixed",
              maxHeight: "367px",
            }}
            loop
            autoPlay
            playsInline
            muted
            src={lastWinVideo.videoUrl}
          />
  </div>
      </FloatingSidebar>:
      null}
      <div className="imageBanner">
        <Slideshow />
      </div>
      <div style={{marginBottom:"30px"}}>
      <div  style={{
          maxWidth: "1500px",
          margin: "20px auto 0px auto",
          padding: "20px 20px 0px 20px",
          backgroundColor: "#ffffffae",
          width: "90%",
          borderRadius: "1.5%"}}>
          <h2 style={{textAlign:"left", margin:"0px"}}>รายการสินค้า</h2>
        </div>
        {typesWithLength ? (
          typesWithLength.map((e, index) => (
            <div className="container-machine" style={{borderTop:"1px dashed #333"}}>
              <h4 className="page-title">{e.type}</h4>
              <div className="card-container" ref={el => (containerRefs.current[index] = el)}>
                {showItemLimit ? 
                  !e.isExpand && e.length > showItemLimit ? (
                    <>
                      {machines[e.type].slice(0, showItemLimit).map((machine) =>
                        machine.price < 1000 ? (
                          <div
                            className="card-machine"
                            key={machine._id}
                            onClick={() => handleClickMachine(machine._id)}
                          >
                            <img
                              className="card-image"
                              src={machine.image}
                              alt={machine.name}
                            />
                            <div className="card-content">
                              <h3 className="card-title">{machine.name}</h3>
                              <p className="card-description">
                                {machine.description}
                              </p>
                              <p className="card-price"> 
                                <img src={coinIcon} alt="" style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                  verticalAlign: "middle"}} /> 
                                {machine.price} coin/play
                              </p>
                            </div>
                          </div>
                        ) : null
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={() => {
                            setTypesWithLength((prevState) => {
                              const updatedTypes = prevState.map((item) => {
                                if (item.type === e.type) {
                                  return { ...item, isExpand: true };
                                }
                                return item;
                              });
                              return updatedTypes;
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "18px",
                          }}
                        >
                          show more...
                        </button>
                      </div>
                    </>
                  ) : (
                    machines[e.type].map((machine) =>
                      machine.price < 1000 ? (
                        <div
                          className="card-machine"
                          key={machine._id}
                          onClick={() => handleClickMachine(machine._id)}
                        >
                          <img
                            className="card-image"
                            src={machine.image}
                            alt={machine.name}
                          />
                          <div className="card-content">
                            <h3 className="card-title">{machine.name}</h3>
                            <p className="card-description">
                              {machine.description}
                            </p>
                            <p className="card-price"> 
                              <img src={coinIcon} alt="" style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                                verticalAlign: "middle"}} /> 
                              {machine.price} coin/play
                            </p>
                          </div>
                        </div>
                      ) : null
                    )
                  )
                :null}
                
                {/* {machines
              .sort((a, b) => {
                if (a.price !== b.price) {
                  return a.price - b.price;
                } else {
                  // If prices are equal, compare by name
                  return a.name.localeCompare(b.name);
                }
              })
              .map((machine) =>
                machine.price < 1000 ? (
                  <div
                    className="card-machine"
                    key={machine._id}
                    onClick={() => handleClickMachine(machine._id)}
                  >
                    <img
                      className="card-image"
                      src={machine.image}
                      alt={machine.name}
                    />
                    <div className="card-content">
                      <h3 className="card-title">{machine.name}</h3>
                      <p className="card-description">{machine.description}</p>
                      <p className="card-price">{machine.price} coin/play</p>
                    </div>
                  </div>
                ) : null
              )} */}
              </div>
            </div>
          ))
        ) : (
          <h2>Loading...</h2>
        )}
      </div>
    </div>
  );
};

export default MachinesPage;
