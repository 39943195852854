import React from "react";
import { Link, Navigate } from "react-router-dom";
import "../loginPage/loginPage.css";
import axios from "axios";
import logoImage from "../resorce/KEEB.png";
import Modal from "react-modal";
import Swal from "sweetalert2";

class RegisterPage extends React.Component {
  state = {
    passwordInValid: false,
    passwordMatchError: false,
    telError: false,
    error: "",
    msg: "",
    redirectToLogin: false,
    isModalOpen: false,
    isLoading:false
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validatePasswordMatch = () => {
    const { password, confirmPassword } = this.state;
    if (password?.length < 6) {
      this.setState({ passwordInValid: true });
    } else {
      this.setState({ passwordInValid: false });
    }
    if (password !== confirmPassword) {
      this.setState({ passwordMatchError: true });
    } else {
      this.setState({ passwordMatchError: false });
    }
  };

  validateTel = () => {
    const { tel } = this.state;
    if (!/^(\+\d{1,3})?\d{10}$/.test(tel)) {
      this.setState({ telError: true });
    } else {
      this.setState({ telError: false });
    }
  };

  handleRegister = async (e) => {
    e.preventDefault();
    if (this.state.isLoading) {
      return
    }
    this.setState({isLoading: true})
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    const tel = e.target.elements.tel.value;
    this.setState({ msg: "" });
    this.setState({ error: "" });

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register`, {
        name: name,
        email: email,
        password: password,
        tel: tel,
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        this.setState({ redirectToLogin: true });
      })
      .catch(({ response }) => {
        this.setState({ error: "Can not register please try again." });
        Swal.fire({
          text: response.data.message,
          icon: "error",
        });
      }).finally(()=>{
        this.setState({isLoading: false})
      })
    // console.log("Register Successful:", response.data);
    // this.setState({ msg: "Register success." });
    // setTimeout(() => {
    //   this.setState({ redirectToLogin: true });
    // }, 1000);
    // Perform any additional actions upon successful login
  };

  render() {
    const {
      passwordInValid,
      passwordMatchError,
      telError,
      error,
      msg,
      redirectToLogin,
      isModalOpen,
    } = this.state;

    if (redirectToLogin) {
      return <Navigate to="/login" />;
    }

    return (
      <>
        <Modal
          className="modal"
          isOpen={isModalOpen}
          onAfterOpen={() => this.setState({ isModalOpen: true })}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          contentLabel="Example Modal"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyItems: "center",
              // justifyContent: "center",
              maxHeight: "70vh",
              // width: "100%",
              overflowY: "auto",
            }}
          >
            <h2>Terms and Conditions</h2>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              1. ข้าพเจ้า ยินยอม ให้ keeb.fun รวบรวม ใช้ หรือเปิดเผยข้อมูล
              ส่วนบุคคลของข้าพเจ้าที่ข้าพเจ้าได้ให้ไว้โดยตรงในขณะที่ติดต่อกับ
              keeb.fun เช่น ข้อมูลพื้นฐานและเอกสารที่เกี่ยวข้อง ข้อมูล
              เกี่ยวกับการติดต่อ ข้อมูลที่ใช้เกี่ยวกับที่อยู่ และข้อมูลอื่น ๆ
              ที่จำเป็นในการใช้งานบริการ
              ข้าพเจ้าขอยืนยันว่าข้อมูลส่วนบุคคลที่มอบให้แก่ keeb.fun
              เป็นข้อมูลที่ถูกต้องแท้จริง หากความเสียหายใด ๆ
              อันเนื่องมาจากการให้ข้อมูลที่ไม่ถูกต้อง
              ข้าพเจ้าจะรับผิดชอบในความเสียหายที่เกิดขึ้น
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              2. ข้าพเจ้า ยินยอม ให้ keeb.fun เก็บข้อมูลส่วนบุคคลของข้าพเจ้า
              จากแหล่งอื่นได้เป็นต้นว่า ส่วนราชการ หน่วยงานของรัฐ
              สถาบันการศึกษาของรัฐและเอกชน
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              3. ข้าพเจ้า ยินยอม ให้ keeb.fun เก็บรักษาข้อมูลส่วนบุคคลของ
              ข้าพเจ้า
              ตราบเท่าที่วัตถุประสงค์ของการนำข้อมูลดังกล่าวไปใช้ยังคงมีอยู่
              เว้นแต่กรณีจำเป็นต้องเก็บรักษา ข้อมูลต่อไปตามกฎหมายกำหนด
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              4. ข้าพเจ้า ยินยอม ให้ keeb.fun และผู้ปฏิบัติงานอื่นๆ ของ keeb.fun
              นำข้อมูลส่วนบุคคลของข้าพเจ้าที่เก็บรวบรวมไปใช้ในการดำเนินการในด้านจัด
              การบริการ และกิจการอื่นใดที่เกี่ยวข้องของกับ keeb.fun
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              5. ข้าพเจ้า ยินยอม ให้ keeb.fun เปิดเผยข้อมูลส่วนบุคคลของ
              ข้าพเจ้าต่อหน่วยงานภายนอกตามกฎหมายและหน่วยงานของรัฐ
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              6. ในกรณีที่ keeb.fun
              มีความจำป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ (ถ้ามี)
              keeb.fun จะขอความยินยอมจากข้าพเจ้าอีกครั้ง
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              7. ข้าพเจ้ารับทราบว่าการให้บริการนี้มิใช่เครื่องมือเล่นการพนัน
              แต่เป็นรูปแบบการจำหน่ายสินค้าในรูปแบบหนึ่งเท่านั้นโดยผู้เล่นจะได้สินค้าอย่างแน่นอนเพื่อจ่ายเงินครบจำนวน
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              8. ข้าพเจ้า ยินยอม
              ว่าข้อมูลของผู้ใช้ผู้พัฒนาสามารถนำไปใช้งานเพื่อการพัฒนาเพื่อประสบการณ์การใช้งานที่ดีขึ้น
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              9. ข้าพเจ้ารับทราบว่า
              การเปลี่ยนแปลงโปรโมชั้นหรือรายการสินค้าหรือนโยบายสามารถทำได้โดยมิต้องแจ้งให้ผู้ใช้ทราบล่วงหน้า
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              10. ข้าพเจ้ารับทราบว่า มติของผู้พัฒนาถือเป็นมติสูงสุด
            </p>
            <p style={{ color: "black", margin: "3px", textAlign: "left" }}>
              11. ข้าพเจ้ารับทราบว่า
              หากผู้ใช้มีการมีการกระทำที่เป็นการขัดกับกฎของการให้บริการ
              ผู้พัฒนามิสิทธิสูงสุดในการพิจารณา
            </p>
            <p
              style={{
                color: "black",
                margin: "3px",
                marginBottom: "10px",
                textAlign: "left",
              }}
            >
              12. วิธีการเพิกถอนความยินยอมและผลการเพิกถอนความยินยอม
              ข้าพเจ้าอาจเพิกถอนความยินยอมทั้งหมดหรือบางส่วนตามหนังสือฉบับนี้
              โดยข้าพเจ้าจะแจ้งให้ keeb.fun
              ทราบเป็นลายลักษณ์อักษรตามแบบฟอร์มซึ่ง keeb.fun กำหนด ที่
              keeb.claw.game@gmail.com และ keeb.fun อาจขอให้เหตุผลแห่งการนั้น
              การเพิกถอนความยินยอมของข้าพเจ้าไม่มีผลกระทบต่อการดำเนินการใดๆที่
              keeb.fun ได้ ดำเนินการไปแล้ว
              ในกรณีที่การเพิกถอนความยินยอมเกิดผลกระทบต่อสิทธิหรือหน้าที่ใดๆของข้าพเจ้า
              ข้าพเจ้ายอมรับ ผลกระทบที่เกิดขึ้นจากการนั้นได้
            </p>

            <button
              type="button"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>
          </div>
        </Modal>
        <div className="my-container">
          <div className="my-card">
            <img className="logo" sizes="" src={logoImage} alt="logo" />
            <h2 style={{margin:"20px"}}>สมัครสมาชิก</h2>
            <form onSubmit={this.handleRegister}>
              <label style={{textAlign:"left", margin:"0"}}>ชื่อเล่น</label>
              <input
                autoCapitalize="none"
                secureTextEntry={true}
                keyboardType={"visible-password"}
                type="text"
                name="name"
                placeholder="Name"
                required
                onChange={this.handleInputChange}
              />
              <label style={{textAlign:"left", margin:"0"}}>อีเมล</label>
              <input
                autoCapitalize="none"
                secureTextEntry={true}
                keyboardType={"visible-password"}
                type="email"
                name="email"
                placeholder="Email"
                required
                onChange={this.handleInputChange}
              />
              <label style={{textAlign:"left", margin:"0"}}>รหัสผ่าน</label>
              <input
                autoCapitalize="none"
                secureTextEntry={true}
                keyboardType={"visible-password"}
                type="password"
                name="password"
                placeholder="Password"
                required
                onChange={this.handleInputChange}
                onBlur={this.validatePasswordMatch}
              />
              {passwordInValid && (
                <p className="error">Set passwords at least 6 digits.</p>
              )}
              <label style={{textAlign:"left", margin:"0"}}>ยืนยันรหัสผ่านอีกครั้ง</label>
              <input
                autoCapitalize="none"
                secureTextEntry={true}
                keyboardType={"visible-password"}
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                required
                onChange={this.handleInputChange}
                onBlur={this.validatePasswordMatch}
              />
              {passwordMatchError && (
                <p className="error">Passwords do not match.</p>
              )}
              <label style={{textAlign:"left", margin:"0"}}>เบอร์โทร</label>
              <input
                autoCapitalize="none"
                secureTextEntry={true}
                keyboardType={"visible-password"}
                type="tel"
                name="tel"
                placeholder="Telephone Number"
                required
                onChange={this.handleInputChange}
                onBlur={this.validateTel}
              />
              {telError && <p className="error">Invalid telephone number.</p>}
              <div style={{textAlign:"left"}}>
                <input type="checkbox" name="agreement" required />
                <label for="agreement">
                  {" "}
                  I agree to these{" "}
                  <a
                    onClick={() => {
                      this.setState({ isModalOpen: true });
                    }}
                    href="#"
                    style={{color:"blue"}}
                  >
                    Terms and Conditions.
                  </a>
                </label>
              </div>

              <button
                type="submit"
                disabled={passwordInValid || passwordMatchError || telError}
              >
                สมัครสมาชิก
              </button>
              {error && <p className="error">{error}</p>}
              {msg && <p className="msg">{msg}</p>}
            </form>
            <p>
              มีบัญชีอยู่แล้ว? <Link to="/login">เข้าสู่ระบบ</Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default RegisterPage;
