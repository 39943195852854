import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import "./crud.css";
import Form from 'react-bootstrap/Form';

export default function ListCoupon() {
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/coupon/`,
        {
          withCredentials: true,
        }
      );
      const res = response.data.data
      setCoupons(res);
      return res
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const deleteCoupon = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/coupon/${id}`,

        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchCoupons();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  return (
      <div className="crud-container">
        <div className="row">
          <div>
            <Link className="btn btn-primary mb-2 float-end" to={"coupon/create"}>
              Create coupon
            </Link>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>name</th>
                    <th>code</th>
                    <th>limit user</th>
                    <th>price</th>
                    <th>expiredAt</th>
                    <th>createdAt</th>
                    <th>delete</th>
                  </tr>
                </thead>
                <tbody>
                  {coupons.length > 0 &&
                    coupons.map((row, key) => (
                      <tr key={row._id}>
                        
                        <td>{row.name}</td>
                        <td>{row.code}</td>
                        <td>{`${row?.userIdsUsed?.length ?? 0} / ${row.limitNumberOfUser}`}</td>
                        <td>{row.price}</td>
                        <td>{row?.expiredAt?.split('T')?.[0] || '-'}</td>
                        <td>{row?.createdAt?.split('T')?.[0] || '-'}</td>
                        <td>
                          <Button
                            className="btn"
                            variant="danger"
                            onClick={() => deleteCoupon(row._id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}
