import axios from "axios";
import React, { useState } from "react";
// import "./adminPage.css";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const UserTopUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Top-Up!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/payment/top-up`,
        {
          email: email,
          amount: amount,
        },

        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  return (
    <div className="container-machine">
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#333",
          padding: "30px",
          borderRadius: "3%",
        }}
      >
        <div style={{ width: "400px" }}>
          <form onSubmit={handleSubmit}>
            <h2 style={{ color: "#f1c40f", margin: "0" }}>User Top-Up</h2>
            <label>
              Email :
              <input
                style={{ color: "black" }}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label>
              Amount :
              <input
                style={{ color: "black" }}
                type="number"
                min={0}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </label>

            <input
              className="nav-link"
              type="submit"
              value="เติมเงิน"
              style={{ margin: "10px" }}
            />
          </form>
        </div>

        {/* <Link to="serder">Open Camera</Link> */}
      </div>
    </div>
  );
};
export default UserTopUp;
