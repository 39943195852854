import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../resorce/KEEB.png";
import Swal from "sweetalert2";

const LoginPage = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`,
        {
          email: username,
          password: password,
        },
        {
          withCredentials: true,
        }
      );
      if (response.data) {
        const token = response.data.token;
        const user = response.data.user;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
      }

      // Perform any additional actions upon successful login

      // Example: Navigate to the dashboard after successful login
      navigate("/");
      window.location.reload();
    } catch (error) {
      setError(error?.response?.data?.message);
      console.log(error);
      Swal.fire({
        text: error?.response?.data?.message,
        icon: "error",
      });
      // Handle login failure
    }
  };

  return (
    <div className="my-container">
      <div className="my-card">
        <img className="logo" sizes="" src={logoImage} alt="logo" />
        <h2 style={{margin:"20px"}}>เข้าสู่ระบบ</h2>
        <form onSubmit={handleLogin}>
          <label style={{textAlign:"left", margin:"0"}}>อีเมล</label>
          <input autoCapitalize="none"
                secureTextEntry={true}
                keyboardType={"visible-password"} 
                type="text" name="username" 
                placeholder="Email" />
          <label style={{textAlign:"left", margin:"0"}}>รหัสผ่าน</label>
          <input autoCapitalize="none"
                secureTextEntry={true}
                keyboardType={"visible-password"} 
                type="password" name="password" 
                placeholder="Password" />
          <button type="submit">เข้าสู่ระบบ</button>
          {error && <p className="error">{error}</p>}
        </form>
        <p>
          มีบัญชีแล้วหรือยัง? <Link to="/register">สมัครสมาชิก</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
