import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import "./crud.css";
import Form from 'react-bootstrap/Form';
import qr from '../../../resorce/lineQrcode.png'

export default function ListClaimRecord() {
  const [claimRecords, setClaimRecords] = useState([]);

  useEffect(() => {
    fetchClaimRecords();
  }, []);

  const fetchClaimRecords = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/claim-record/`,
        {
          withCredentials: true,
        }
      );
      const res = response.data.data
      setClaimRecords(res);
      return res
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const toggleShippingStatus = async (id) => {
    const claimRecord = claimRecords.find(prize => prize._id === id)
    const prizeIsEnableStatus = claimRecord.status === 'shipping' || false
    const statusToPersis = !prizeIsEnableStatus ? "shipping" : 'pending'
    const isConfirm = await Swal.fire({
      title: `Are you sure to togle shipping status?`,
      text: `set to ${statusToPersis}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, toggle!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/claim-record/${id}`,
        {
          status: statusToPersis
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchClaimRecords();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const handlePrint = (record) => {
    const printContents = `
      <div>
        <hr />
        <h1>กรุณานำส่ง</h1>
        <h2>ที่อยู่ผู้รับ</h2>
        <p><strong>ชื่อ:</strong> ${record.shippingName}</p>
        <p><strong>ที่อยู่:</strong> ${record.address}</p>
        <p><strong>เบอร์โทร:</strong> ${record.tel}</p>
        <p><strong>อีเมล:</strong> ${record.email}</p>
        <hr />
        ตัด
        <hr />
        <h2>ผู้ฝากนำส่ง</h2>
        <p>keeb.fun</p>
        <p><strong>เบอร์โทร:</strong> 083-722-7987</p>
        <p><strong>อีเมล:</strong> keeb.claw.game@gmail.com</p>
        <hr />
        ตัด
        <hr />
        <h1>รายการสินค้าที่ต้องได้รับ:</h1>
        <ul>
          ${record.items.map(i => `<li>${i}</li>`).join('')}
        </ul>
        <h3>สแกนไลน์เพื่อนเล่นกิจกรรมรับเหรียญฟรี<h3>
         <img id='qrCode' style="height: 100px; width: 100px; margin: 0 10px;" 
             src=${qr} alt="QR Code">
      </div>
    `;
    const printWindow = window.open('', '', 'height=900,width=1200');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    
    // Wait for the image to load before closing the print window
    const img = printWindow.document.getElementById('qrCode');
    img.onload = () => {
      printWindow.print();
      printWindow.close();
    };
    
    // Optional: Handle image loading error
    img.onerror = () => {
      console.error('Image failed to load.');
      printWindow.print(); // Print even if the image fails to load
      printWindow.close();
    };
  };

  return (
      <div className="crud-container">
        <div className="row">
          <div>
            <Link className="btn btn-primary mb-2 float-end" to={"prize/create"}>
              Create Prize
            </Link>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>status</th>
                    <th>shippingName</th>
                    <th>address</th>
                    <th>tel</th>
                    <th>email</th>
                    <th>items</th>
                    <th>createdAt</th>
                    <th>isShipping</th>
                    <th>Print</th>
                  </tr>
                </thead>
                <tbody>
                  {claimRecords.length > 0 &&
                    claimRecords.map((row, key) => (
                      <tr key={row._id}>
                        <td style={{color:row.status === 'shipping'?'green':'red'}}>
                        {row.status}
                        </td>
                        <td>{row.shippingName}</td>
                        <td>{row.address || '-'}</td>
                        <td>{row.tel}</td>
                        <td>{row.email}</td>
                        <td>{row.items.map(i=><li>{i}</li>) ?? "-"}</td>
                        <td>{row?.createdAt?.split('T')?.[0] || '-'}</td>
                        <td>
                          <Form>
                            <Form.Check // prettier-ignore
                              type="switch"
                              checked = {row.status === 'shipping' ||false}
                              onClick={()=>{
                                toggleShippingStatus(row._id)
                              }}
                            />
                          </Form>
                        </td>
                        <td>
                          <Button onClick={() => handlePrint(row)}>Print Label</Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}
